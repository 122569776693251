import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    --color-background: #F5FAF5;
    --color-primary: #4caf50;
    --color-primary-dark: #388e3c;
    --color-accent: #FFC107;
    --color-text-base: #212121;
    --color-text-secondary: #757575;
    --color-text-light: #F5FAF5;

    font-size: 60%;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: var(--color-background);
    color: var(--color-text-base);
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font: 500 1.6rem 'Roboto Condensed';
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  button {
    cursor: pointer;
  }

  @media (min-width: 700px) {
  :root {
    font-size: 62.5%;
  }
}
`;
