import styled from "styled-components";

export const Container = styled.header`
  padding: 1.5rem 0;
  background-color: var(--color-primary);
`;

export const HeaderContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: row;
  }

  .logo {
    width: 10rem;
    height: 10rem;
    margin-left: 1.2rem;
  }

  h1 {
    color: var(--color-text-light);
    margin-left: 2rem;
    font-size: 2.6rem;
    margin: auto 0 auto 1.2rem;
  }

  .play-store-badge {
    display: none;
  }

  @media (min-width: 425px) {
    .play-store-badge {
      display: block;
    }
  }

  @media (min-width: 768px) {
    max-width: 1100px;
    padding: 0 10%;
  }

  @media (min-width: 1100px) {
    max-width: 1100px;
    padding: 0;
  }
`;
