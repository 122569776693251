import React, {
  InputHTMLAttributes,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import { useField } from "@unform/core";
import { MdDelete } from "react-icons/md";

import { Container } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  tabIndex?: number;
  onResetMoneyInput?: (name: string) => void;
}

const MoneyInput: React.FC<InputProps> = ({
  name,
  tabIndex,
  onResetMoneyInput,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <input
        type="number"
        min={0}
        onInput={(e) => {
          if (e.currentTarget.value.length > 12) {
            e.currentTarget.value = e.currentTarget.value.slice(0, 12);
          }
          e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, "");
        }}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        tabIndex={tabIndex}
        {...rest}
      />
      <button
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClick={onResetMoneyInput ? () => onResetMoneyInput(name) : () => {}}
        type="button"
        aria-label="Limpar"
      >
        <MdDelete size={24} color="var(--color-text-light)" />
      </button>
    </Container>
  );
};

export default MoneyInput;
