import styled from "styled-components";

export const Container = styled.footer`
  height: 16rem;
  font-size: 2rem;
  background-color: var(--color-primary);
  color: var(--color-text-light);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    strong {
      a {
        text-decoration: none;
        color: var(--color-accent);
      }
    }
  }
`;
