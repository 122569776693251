/* eslint-disable jsx-a11y/tabindex-no-positive */
import React from "react";

import { Container } from "./styles";

import Header from "../../components/Header";
import Counter from "../../components/Counter";
// import Contents from "../../components/Contents";
import Footer from "../../components/Footer";

const Home: React.FC = () => (
  <Container>
    <Header />
    <Counter />
    {/* <Contents /> */}
    <Footer />
  </Container>
);

export default Home;
