import { shade } from "polished";
import styled, { css } from "styled-components";

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #fff;
  border-radius: 1.6rem;
  max-width: 22.8rem;
  height: 4.8rem;
  border: 2px solid transparent;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  display: flex;
  align-items: center;

  ${(props) =>
    props.isFocused &&
    css`
      border: 2px solid var(--color-accent);
    `}

  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: var(--color-text-base);
    padding: 1.6rem;
    max-width: 12rem;
    font: 500 2rem "Roboto Condensed";

    &::placeholder {
      color: #666360;
    }
  }

  button {
    background-color: var(--color-primary-dark);
    width: 4.8rem;
    height: 4.8rem;
    border: 0;
    margin: -2px;
    border-radius: 0 1.6rem 1.6rem 0;

    &:hover {
      background: ${shade(0.2, "#388e3c")};
    }
  }

  @media (min-width: 768px) {
    input {
      max-width: 18rem;
    }
  }
`;
