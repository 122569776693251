/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useCallback, useRef, useState, useMemo } from "react";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { Container, MoneySection, MoneyItem, MoneyImage } from "./styles";

import MoneyInput from "../MoneyInput";

import brl_coin_100 from "../../assets/brl_coin_100.png";
import brl_coin_50 from "../../assets/brl_coin_50.png";
import brl_coin_25 from "../../assets/brl_coin_25.png";
import brl_coin_10 from "../../assets/brl_coin_10.png";
import brl_coin_5 from "../../assets/brl_coin_5.png";
import brl_coin_1 from "../../assets/brl_coin_1.png";
import brl_note_200 from "../../assets/brl_note_200.jpg";
import brl_note_100 from "../../assets/brl_note_100.jpg";
import brl_note_50 from "../../assets/brl_note_50.jpg";
import brl_note_20 from "../../assets/brl_note_20.jpg";
import brl_note_10 from "../../assets/brl_note_10.jpg";
import brl_note_5 from "../../assets/brl_note_5.jpg";
import brl_note_2 from "../../assets/brl_note_2.jpg";

interface CoinsData {
  brl_coin_100: number;
  brl_coin_50: number;
  brl_coin_25: number;
  brl_coin_10: number;
  brl_coin_5: number;
  brl_coin_1: number;
}

interface NotesData {
  brl_note_200: number;
  brl_note_100: number;
  brl_note_50: number;
  brl_note_20: number;
  brl_note_10: number;
  brl_note_5: number;
  brl_note_2: number;
}

const Home: React.FC = () => {
  const coinsFormRef = useRef<FormHandles>(null);
  const notesFormRef = useRef<FormHandles>(null);

  const [coins, setCoins] = useState<CoinsData>({} as CoinsData);
  const [notes, setNotes] = useState<NotesData>({} as NotesData);

  const coinsTotal = useMemo(() => {
    let total = 0;
    total += coins.brl_coin_100 ? Number(coins.brl_coin_100) * 1.0 : 0;
    total += coins.brl_coin_50 ? Number(coins.brl_coin_50) * 0.5 : 0;
    total += coins.brl_coin_25 ? Number(coins.brl_coin_25) * 0.25 : 0;
    total += coins.brl_coin_10 ? Number(coins.brl_coin_10) * 0.1 : 0;
    total += coins.brl_coin_5 ? Number(coins.brl_coin_5) * 0.05 : 0;
    total += coins.brl_coin_1 ? Number(coins.brl_coin_1) * 0.01 : 0;
    return total;
  }, [coins]);

  const notesTotal = useMemo(() => {
    let total = 0;
    total += notes.brl_note_200 ? Number(notes.brl_note_200) * 200 : 0;
    total += notes.brl_note_100 ? Number(notes.brl_note_100) * 100 : 0;
    total += notes.brl_note_50 ? Number(notes.brl_note_50) * 50 : 0;
    total += notes.brl_note_20 ? Number(notes.brl_note_20) * 20 : 0;
    total += notes.brl_note_10 ? Number(notes.brl_note_10) * 10 : 0;
    total += notes.brl_note_5 ? Number(notes.brl_note_5) * 5 : 0;
    total += notes.brl_note_2 ? Number(notes.brl_note_2) * 2 : 0;
    return total;
  }, [notes]);

  const total = useMemo(() => {
    return coinsTotal + notesTotal;
  }, [coinsTotal, notesTotal]);

  const coinsFormatted = useMemo(() => {
    return coinsTotal
      ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(coinsTotal)
      : "R$ 0,00";
  }, [coinsTotal]);

  const notesFormatted = useMemo(() => {
    return notesTotal
      ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(notesTotal)
      : "R$ 0,00";
  }, [notesTotal]);

  const totalFormatted = useMemo(() => {
    return total
      ? new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(total)
      : "R$ 0,00";
  }, [total]);

  const handleCoinChange = useCallback(() => {
    const data = coinsFormRef.current?.getData() as CoinsData;
    setCoins(data);
  }, []);

  const handleNoteChange = useCallback(() => {
    const data = notesFormRef.current?.getData() as NotesData;
    setNotes(data);
  }, []);

  const onResetCoinMoneyInput = useCallback(
    (name) => {
      coinsFormRef.current?.setFieldValue(name, "");
      handleCoinChange();
    },
    [handleCoinChange]
  );

  const onResetNoteMoneyInput = useCallback(
    (name) => {
      notesFormRef.current?.setFieldValue(name, "");
      handleNoteChange();
    },
    [handleNoteChange]
  );

  const onResetAllCoins = useCallback(() => {
    coinsFormRef.current?.setData({
      brl_coin_100: "",
      brl_coin_50: "",
      brl_coin_25: "",
      brl_coin_10: "",
      brl_coin_5: "",
      brl_coin_1: "",
    });
    handleCoinChange();
  }, [handleCoinChange]);

  const onResetAllNotes = useCallback(() => {
    notesFormRef.current?.setData({
      brl_note_200: "",
      brl_note_100: "",
      brl_note_50: "",
      brl_note_20: "",
      brl_note_10: "",
      brl_note_5: "",
      brl_note_2: "",
    });
    handleNoteChange();
  }, [handleNoteChange]);

  return (
    <Container>
      <MoneySection className="coins_section">
        <h2>Moedas</h2>
        <Form
          ref={coinsFormRef}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          onChange={handleCoinChange}
        >
          <MoneyItem>
            <MoneyImage totalWidth={81} totalHeight={81}>
              <img
                src={brl_coin_100}
                alt="Moeda de 1 real"
                width="81"
                height="81"
              />
            </MoneyImage>
            <MoneyInput
              name="brl_coin_100"
              tabIndex={1}
              onResetMoneyInput={onResetCoinMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={81} totalHeight={81}>
              <img
                src={brl_coin_50}
                alt="Moeda de 50 centavos"
                width="69"
                height="69"
              />
            </MoneyImage>
            <MoneyInput
              name="brl_coin_50"
              tabIndex={2}
              onResetMoneyInput={onResetCoinMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={81} totalHeight={81}>
              <img
                src={brl_coin_25}
                alt="Moeda de 25 centavos"
                width="75"
                height="75"
              />
            </MoneyImage>
            <MoneyInput
              name="brl_coin_25"
              tabIndex={3}
              onResetMoneyInput={onResetCoinMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={81} totalHeight={81}>
              <img
                src={brl_coin_10}
                alt="Moeda de 10 centavos"
                width="60"
                height="60"
              />
            </MoneyImage>
            <MoneyInput
              name="brl_coin_10"
              tabIndex={4}
              onResetMoneyInput={onResetCoinMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={81} totalHeight={81}>
              <img
                src={brl_coin_5}
                alt="Moeda de 5 centavos"
                width="65"
                height="65"
              />
            </MoneyImage>
            <MoneyInput
              name="brl_coin_5"
              tabIndex={5}
              onResetMoneyInput={onResetCoinMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={81} totalHeight={81}>
              <img
                src={brl_coin_1}
                alt="Moeda de 1 centavo"
                width="51"
                height="51"
              />
            </MoneyImage>
            <MoneyInput
              name="brl_coin_1"
              tabIndex={6}
              onResetMoneyInput={onResetCoinMoneyInput}
            />
          </MoneyItem>
          <p>
            Total em Moedas
            <br />
            <strong>{coinsFormatted}</strong>
          </p>
          <button
            type="button"
            onClick={onResetAllCoins}
            aria-label="Limpar moedas"
          >
            LIMPAR MOEDAS
          </button>
        </Form>
      </MoneySection>
      <MoneySection className="notes_section">
        <h2>Notas</h2>
        <Form
          ref={notesFormRef}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          onChange={handleNoteChange}
        >
          <MoneyItem>
            <MoneyImage totalWidth={120} totalHeight={69.45}>
              <img src={brl_note_200} alt="Nota de 200 reais" width="110" />
            </MoneyImage>
            <MoneyInput
              name="brl_note_200"
              tabIndex={7}
              onResetMoneyInput={onResetNoteMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={120} totalHeight={69.45}>
              <img src={brl_note_100} alt="Nota de 100 reais" width="120" />
            </MoneyImage>
            <MoneyInput
              name="brl_note_100"
              tabIndex={8}
              onResetMoneyInput={onResetNoteMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={120} totalHeight={69.45}>
              <img src={brl_note_50} alt="Nota de 50 reais" width="115" />
            </MoneyImage>
            <MoneyInput
              name="brl_note_50"
              tabIndex={9}
              onResetMoneyInput={onResetNoteMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={120} totalHeight={69.45}>
              <img src={brl_note_20} alt="Nota de 20 reais" width="110" />
            </MoneyImage>
            <MoneyInput
              name="brl_note_20"
              tabIndex={10}
              onResetMoneyInput={onResetNoteMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={120} totalHeight={69.45}>
              <img src={brl_note_10} alt="Nota de 10 reais" width="105" />
            </MoneyImage>
            <MoneyInput
              name="brl_note_10"
              tabIndex={11}
              onResetMoneyInput={onResetNoteMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={120} totalHeight={69.45}>
              <img src={brl_note_5} alt="Nota de 5 reais" width="100" />
            </MoneyImage>
            <MoneyInput
              name="brl_note_5"
              tabIndex={12}
              onResetMoneyInput={onResetNoteMoneyInput}
            />
          </MoneyItem>
          <MoneyItem>
            <MoneyImage totalWidth={120} totalHeight={69.45}>
              <img src={brl_note_2} alt="Nota de 2 reais" width="95" />
            </MoneyImage>
            <MoneyInput
              name="brl_note_2"
              tabIndex={13}
              onResetMoneyInput={onResetNoteMoneyInput}
            />
          </MoneyItem>
          <p>
            Total em Notas
            <br />
            <strong>{notesFormatted}</strong>
          </p>
          <button
            type="button"
            onClick={onResetAllNotes}
            aria-label="Limpar notas"
          >
            LIMPAR NOTAS
          </button>
        </Form>
      </MoneySection>
      <strong className="total">
        TOTAL =&nbsp;
        {totalFormatted}
      </strong>
    </Container>
  );
};

export default Home;
