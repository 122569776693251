/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";

import { Container, HeaderContent } from "./styles";

import logo from "../../assets/logo.png";

const Header: React.FC = () => (
  <Container>
    <HeaderContent>
      <div>
        <img className="logo" src={logo} alt="Logo" />
        <h1>
          Contador:
          <br />
          Moedas e Notas
        </h1>
      </div>
      <div>
        <a
          className="play-store-badge"
          href="https://play.google.com/store/apps/details?id=com.lucasbarzan.contadordemoedasenotas&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            alt="Disponível no Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
            height="72"
          />
        </a>
      </div>
    </HeaderContent>
  </Container>
);

export default Header;
