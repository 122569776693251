import React from "react";

import Home from "./pages/Home";

import GlobalStyles from "./styles/global";

const App: React.FC = () => (
  <>
    <Home />
    <GlobalStyles />
  </>
);

export default App;
