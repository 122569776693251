import { shade } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.main`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas:
    "coins"
    "notes"
    "total";
  overflow: hidden;
  min-height: 100vh;

  .coins_section {
    grid-area: coins;
  }

  .notes_section {
    grid-area: notes;
  }

  .total {
    grid-area: total;
    text-align: center;
    font-size: 2.8rem;
    color: var(--color-accent);
    font-weight: 700;
    margin: 1.6rem 0;
  }

  @media (min-width: 1024px) {
    display: grid;
    padding: 0 10%;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "coins notes"
      "total total";
  }
`;

export const MoneySection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;

  h2 {
    color: var(--color-accent);
    font-size: 2.4rem;
    margin-bottom: 1.6rem;
  }

  form {
    text-align: center;

    p {
      font-size: 1.8rem;
      margin-top: 1.2rem;

      strong {
        font-size: 2.4rem;
      }
    }

    > button {
      background-color: var(--color-primary-dark);
      font-size: 1.6rem;
      font-weight: 700;
      width: 19.2rem;
      height: 4.8rem;
      border: 0;
      border-radius: 1.6rem;
      color: var(--color-text-light);
      margin-top: 2.4rem;

      &:hover {
        background: ${shade(0.2, "#388e3c")};
      }
    }
  }
`;

export const MoneyItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100%; */
`;

interface MoneyImageProps {
  totalWidth?: number;
  totalHeight?: number;
}

export const MoneyImage = styled.div<MoneyImageProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;

  ${(props) =>
    props.totalWidth &&
    css`
      width: ${props.totalWidth}px;
    `}

  ${(props) =>
    props.totalHeight &&
    css`
      height: ${props.totalHeight}px;
    `}

  @media (min-width: 375px) {
    margin-right: 3.2rem;
  }
`;
