/* eslint-disable jsx-a11y/accessible-emoji */
import React from "react";

import { Container } from "./styles";

const Footer: React.FC = () => (
  <Container>
    <a href="https://play.google.com/store/apps/details?id=com.lucasbarzan.contadordemoedasenotas&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
      <img
        alt="Disponível no Google Play"
        src="https://play.google.com/intl/en_us/badges/static/images/badges/pt-br_badge_web_generic.png"
        height="72"
      />
    </a>
    <p>
      Feito com ❤️ por&nbsp;
      <strong>
        <a href="http://lucasbarzan.com">Lucas Barzan</a>
      </strong>
    </p>
  </Container>
);

export default Footer;
